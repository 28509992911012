/* font applied in whole body */
body {
    font-family: "Poppins", sans-serif !important;
    overflow-y: scroll; /* Show scrollbars */
}

// write common css
.App {
    text-align: start !important;
    display: flex !important;
    flex-direction: column !important;
    min-height: 100vh !important;
}

// footer background
.bg-footer {
    background-color: #101828 !important;
}

// slider background
.slider-background {
    background-color: #101828 !important;
    background-image: url("../../images/sliderBgLine.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

// sliderItem opacity
.opacity-1 {
    --bs-bg-opacity: 0.1 !important;
}

// background blur
.background-blur {
    backdrop-filter: blur(10px) !important;
}

.carousel-indicators button {
    width: 2.5rem !important;
    height: 0.45rem !important;

    border-top-left-radius: 5px 15px;
    border-bottom-left-radius: 5px 15px;
    border-top-right-radius: 5px 15px;
    border-bottom-right-radius: 5px 15px;
}

.complexSliderClass {
    .carousel-indicators {
        button {
            width: 0.5rem !important;
            height: 0.5rem !important;

            border-radius: 50% !important;
            background-color: rgb(185, 189, 185) !important;
        }
        .active {
            background-color: $primary !important;
        }
    }
}

.bannerSliderClass {
    .carousel-indicators {
        bottom: -45px !important;

        button {
            width: 0.5rem !important;
            height: 0.5rem !important;

            border-radius: 50% !important;
            background-color: rgb(185, 189, 185) !important;
        }
        .active {
            background-color: $primary !important;
        }
    }
}

// .carousel-indicators button:not(:first-child) {
//   margin-left: 0.5rem;
// }

.carousel-indicators .active {
    background-color: #fa5000;
}

.price-box-shadow {
    box-shadow: -2px -1px 14px 2px rgba(120, 119, 120, 0.2);
}

.card-box-shadow {
    box-shadow: 0px 10px 25px -20px rgba(0, 0, 0, 0.65);
    -webkit-box-shadow: 0px 10px 25px -20px rgba(0, 0, 0, 0.65);
    -moz-box-shadow: 0px 10px 25px -20px rgba(0, 0, 0, 0.65);
}

.filter-box-shadow {
    box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.2);
}

.floor-plan-swiper-slider {
    .swiper-wrapper {
        align-items: center !important;
    }

    .swiper-slide {
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        width: 100% !important;
    }
}

.venue-swiper-slider {
    .swiper-slide {
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        width: auto !important;
        max-width: 310px !important;
        height: auto !important;
    }
}

.h-200px {
    height: 200px !important;
}

.single-page-banner-height {
    height: 65vh !important;
}

.single-page-banner-height-30vh {
    height: 30vh !important;
}

.w-85vw {
    width: 85vw !important;
}

.filter-selection-width {
    // width: 130px !important;
    width: 100% !important;
}

// react - select
.css-13cymwt-control {
    border: 0 !important;
}

// .css-1fdsijx-ValueContainer {
//     padding: 0 !important;
// }

.h-40vh {
    height: 40vh !important;
}

.book-venue-bottom {
    bottom: 34% !important;
}

.book-venue-width {
    width: 88% !important;
}

.bg-accordionHeader {
    button {
        background-color: transparent !important;
        border: none !important;
    }
}

.accordion-button:focus {
    box-shadow: none !important;
}

.line-clamp-2 {
    line-clamp: 2 !important;
    -webkit-line-clamp: 2 !important;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.center-policy {
    p {
        margin-bottom: 0 !important;
    }
}

.btn-outline-primary:hover {
    color: white !important;
}

.text-primary-hover:hover {
    color: $primary !important;
}

.input-group:focus-within {
    outline: 1px solid $primary !important;
}

.input-group:focus-within *:focus {
    border-color: transparent !important;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0px $primary !important;
}
